import React from "react";

type Props = {
  color?: string;
  height: string;
  width: string;
};

export const StackOverflowIcon = ({ color = "#FE7A16", height, width }: Props) => (
  <svg height={height} role="img" viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <title>Stack Overflow icon</title>
    <path
      d="M18.986 21.865v-6.404h2.134V24H1.844v-8.539h2.13v6.404h15.012zM6.111 19.731H16.85v-2.137H6.111v2.137zm.259-4.852l10.48 2.189.451-2.07-10.478-2.187-.453 2.068zm1.359-5.056l9.705 4.53.903-1.95-9.706-4.53-.902 1.936v.014zm2.715-4.785l8.217 6.855 1.359-1.62-8.216-6.853-1.35 1.617-.01.001zM15.751 0l-1.746 1.294 6.405 8.604 1.746-1.294L15.749 0h.002z"
      fill={color}
    />
  </svg>
);
